
					@import "@/styles/_variables.scss";
					@import "@/styles/_utilities.scss";
				

.el-table {
	:deep(tr.is-disabled) {
		background-color: #f7f7f7;
	}

	:deep(tr.is-disabled .cell),
	:deep(tr.is-disabled .cell a:not(.activate-link)) {
		color: #acacac;
	}
}
@media screen and (max-width: 767px) {
	.el-table {
		max-height: 45vh !important;
	}
}

h1 {
	margin-bottom: 30px;
}
.show-inactive {
	margin-top: 26px;
}

.el-button {
	min-height: 0;
}

.el-button.is-circle {
	display: inline-block;
	margin: 1px auto;
	padding: 7px;
	letter-spacing: 0;

	&.edit-btn {
		color: $blue;
		background-color: #e1f1f6;
		border-color: #78bcd9;
		margin-right: 10px;

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
			color: #fff;
			background-color: $blue;
			border-color: $blue;
		}
	}
	&.activate-btn {
		color: #57bb61;
		background-color: #e2f7e5;
		border-color: #94e19a;
		margin-left: 40px;

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
			color: #fff;
			background-color: $success-green;
			border-color: $success-green;
		}
	}
}

.createBtnWrap {
	margin-bottom: 0;
	text-align: right;
}

.create-button {
	width: auto;
	height: 28px;
	border: 1px solid $success-green !important;
	border-radius: 20px;
	background-color: #fff !important;
	color: #57bb61;
	font-size: 11px;
	text-transform: uppercase;
	padding: 6px 10px 4px 32px;
	position: relative;
	top: 21px;

	&:hover .plus-icon {
		color: #fff;
		background-color: $success-green;
	}

	.plus-icon {
		font-size: 1.5em;
		line-height: 140%;
		text-align: center;
		padding: 1px 0 0;
		color: #57bb61;
		border: 1px solid $success-green;
		background-color: #e2f7e5;
		border-radius: 20px;
		height: 25px;
		width: 26px;
		position: absolute;
		left: -1px;
		top: -1px;
		transition: color 0.1s, background-color 0.1s;
	}
}

@media screen and (max-width: 480px) {
}
@media screen and (max-width: 767px) {
	h1 {
		text-align: left;
		width: 100%;
		margin-bottom: 20px;
	}

	.show-inactive {
		margin: 0 auto 15px;
		text-align: center;
		width: 100%;
	}

	.createBtnWrap {
		margin-bottom: 25px;
		text-align: center;
	}

	.create-button {
		top: 0;
	}
}
